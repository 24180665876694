import React from "react";
import { styled } from "../../../stitches.config";
import { Benefit } from "../../types/benefit";
type Props = {
  benefits: Benefit[];
};

export const OurVacanciesCards: React.FC<Props> = ({ benefits }) => {
  return (
    <Container>
      {benefits.map((el, i) => (
        <OurVacanciesCard key={i}>
          <AdvantagesBox key={i}>
            <IconWrap>
              <Icon src={el.attributes.icon.data.attributes.url} />
            </IconWrap>
            <SubTitleBox>
              <Title>{el.attributes.title}</Title>
              <Text>{el.attributes.description}</Text>
            </SubTitleBox>
          </AdvantagesBox>
        </OurVacanciesCard>
      ))}
    </Container>
  );
};

const Container = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  flexDirection: "row",
  gap: 30,
  "@md": {
    gap: 32,
  },
});

const OurVacanciesCard = styled("div", {
  display: "flex",
  gap: 30,
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: "wrap",
  flexBasis: "calc((100% - 30px) / 2)",
  "@md": {
    flexBasis: "unset",
    maxWidth: 296,
  },
});

const Title = styled("h2", {
  margin: 0,
  fontWeight: 600,
  fontSize: 17,
  lineHeight: "24px",
  color: "$heading",
});

const Text = styled("p", {
  margin: 0,
  fontWeight: 400,
  fontSize: 15,
  lineHeight: "20px",
  color: "$text",
});

const Icon = styled("img", {
  height: "16px",
  width: "16px",
});

const IconWrap = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexBasis: "24px",
  flexShrink: 0,
  height: "24px",
  borderRadius: "4px",
  backgroundColor: "$accent",
});

const AdvantagesBox = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  gap: 12,
});

const SubTitleBox = styled("div", {
  display: "flex",
  flexDirection: "column",
});
