import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { styled } from "../../../stitches.config";
import { useAppSelector } from "../../hooks/ReduxHooks";
import { Question } from "../../views/Questions/Questions";
import { Accordion } from "./Accordion";

export const VacanciesTabs: React.FC = () => {
  const { t } = useTranslation();
  const { primaryNumber } = useAppSelector((state) => state.contactInfo);

  const accordionData: Question[] = [
    {
      title: "career.tabs.sub.title1",
      content: "career.tabs.sub.text1",
    },
    {
      title: "career.tabs.sub.title2",
      content: "career.tabs.sub.text2",
    },
    {
      title: "career.tabs.sub.title3",
      content: "career.tabs.sub.text3",
    },
    {
      title: "career.tabs.sub.title4",
      content: "career.tabs.sub.text4",
    },
    {
      title: "career.tabs.sub.title5",
      content: "career.tabs.sub.text5",
    },
    {
      title: "career.tabs.sub.title6",
      content: "career.tabs.sub.text6",
    },
    {
      title: "career.tabs.sub.title7",
      content: "career.tabs.sub.text7",
      variables: { primaryNumber },
    },
  ];

  return (
    <Container>
      <Wrapper>
        <Title>{t("career.tabs.title")}</Title>
        <Tabs>
          {accordionData.map(({ title, content, variables }, i) => (
            <Accordion
              key={i}
              variables={variables}
              title={title}
              content={content}
            />
          ))}
        </Tabs>
      </Wrapper>
    </Container>
  );
};

const Tabs = styled("div", {});

const Container = styled("div", {
  background: "$primaryBackground",
  padding: "60px 16px",
  height: "fix-content",
  marginBottom: 40,
  "@md": {
    padding: "60px 0",
    marginBottom: 0,
  },
});

const Wrapper = styled("div", {
  maxWidth: "1280px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@md": {
    flexDirection: "row",
  },
});

const Title = styled("h1", {
  margin: "0 0 32px",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  colot: "text",
  "@md": {
    fontSize: "24px",
    lineHeight: "28px",
  },
});
