import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useReducer, useState } from "react";
import { styled } from "../../../stitches.config";
import Minus from "../../images/career/minus.svg";
import Plus from "../../images/career/plus.svg";

type Props = {
  title: string;
  content: string;
  variables?: {
    [key: string]: string;
  };
};

export const Accordion: React.FC<Props> = ({ title, content, variables }) => {
  const [isOpened, setIsOpened] = useReducer((val) => !val, false);
  const { t } = useTranslation();

  return (
    <Box onClick={setIsOpened}>
      <Icon isOpened={isOpened} />
      <TextContainer>
        <Title>{t(title)}</Title>
        <Text variant={isOpened}>{t(content, variables)}</Text>
      </TextContainer>
    </Box>
  );
};

const Box = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  flexDirection: "row-reverse",
  width: "100%",
  gap: 20,
  borderBottom: "1px solid $white",
  padding: "0 0 16px",
  marginBottom: 24,
  "&:last-child": {
    borderBottom: "none",
  },
  "@md": {
    maxWidth: 900,
  },
});

const Title = styled("h2", {
  margin: "0 0 8px",
  fontWeight: 700,
  fontSize: "17px",
  lineHeight: "24px",
  width: "100%",
});

const Text = styled("p", {
  margin: 0,
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "20px",
  transition: "max-height 1s ease-in-out, opacity 1000ms ease",
  opacity: 0,
  variants: {
    variant: {
      true: {
        opacity: 1,
        maxHeight: "500px",
      },
      false: {
        overflow: "hidden",
        maxHeight: "0",
      },
    },
  },
});

const TextContainer = styled("div", {
  flexBasis: "calc(100% - 45px)",
});

const Icon = styled("div", {
  width: 25,
  height: 25,
  flexBasis: 25,
  cursor: "pointer",
  background: `url(${Plus}) no-repeat`,
  transition: "background 1s ease",
  variants: {
    isOpened: {
      true: {
        background: `url(${Minus}) no-repeat`,
      },
    },
  },
});
