import React, { useEffect, useState } from "react";
import { styled } from "../../../stitches.config";
import { routes } from "../../routes/routes";
import { Layout } from "../../layout";
import { Colors, Hero } from "../../components/shared/Hero";
import { Button } from "../../components/shared/Button";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { OpenVacancies } from "../../components/Career/OpenVacancies";
import { Vacancy } from "../../types/vacancy";
import { useScreenResolution } from "../../hooks/useScreenResolution";
import { VacanciesTabs } from "../../components/Career/VacanciesTabs";
import { LiveSupport } from "../../components/shared/LiveSupport";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import MainImage from "../../images/career/career-image.svg";
import { sendRequestToAPI } from "../../api/graphqlRequest";
import { PageHead } from "../../components/shared/PageHead";
import { OurVacanciesCards } from "../../components/Career/OurVacanciesCards";
import { Benefit } from "../../types/benefit";

function Career() {
  const { resolution } = useScreenResolution();
  const { navigate, language } = useI18next();
  const { t } = useTranslation();
  const [vacancies, setVacancies] = useState<Vacancy[]>([]);
  const [benefits, setBenefits] = useState<Benefit[]>([]);

  const bgColor = Colors.green;

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await sendRequestToAPI(
          `query($locale: I18NLocaleCode){
            vacancies {
              data {
                attributes {
                  city
                  title
                  description
                  slug
                  tasks
                }
              }
            }
            careerBenefits(locale: $locale, pagination: { limit: 100 }){
              data{
                attributes{
                  description
                  title
                  icon{
                    data{attributes{
                      url
                    }}
                  }
                }
              }
            }
          }
        `,
          { locale: language }
        );
        setVacancies(data.data.vacancies.data);
        setBenefits(data.data.careerBenefits.data);
      } catch (err) {
        return err;
      }
    };
    getData();
  }, []);

  return (
    <Layout>
      <PageHead title={t("career.title")} />
      <StyledHero
        pic={{ wrap: Image, src: MainImage }}
        backgroundColor={bgColor}
      >
        <Heading>{t("career.title")}</Heading>
        <Description>{t("career.description")}</Description>
        <ButtonsWrap>
          <Button
            onClick={() => navigate(routes.contact.employment)}
            colorScheme="dark"
          >
            {t("jetztBewerben")}
          </Button>
        </ButtonsWrap>
      </StyledHero>
      <VacanciesSection>
        <Container>
          <VacanciesTitle>{t("career.vacancy.title1")}</VacanciesTitle>
          <OpenVacancies vacancies={vacancies} />
        </Container>
      </VacanciesSection>
      <OurVacancy>
        <VacanciesTitle>{t("career.vacancy.title2")}</VacanciesTitle>
        <OurVacanciesCards benefits={benefits} />
      </OurVacancy>
      <VacanciesTabs />
      {/* {resolution === "mobile" && <LiveSupport colorScheme="green" />} */}
    </Layout>
  );
}

export default Career;

const Container = styled("div", {
  padding: "60px 16px",
  maxWidth: "1280px",
  margin: "0 auto",

  "@md": {
    padding: "30px 0",
  },
});

const VacanciesSection = styled("section", {
  backgroundColor: "$secondaryBackground",
});

const OurVacancy = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "60px 16px",
  maxWidth: "1280px",
  margin: "0 auto",
  "@md": {
    gap: 24,
    padding: "80px 0",
  },
});

const VacanciesTitle = styled("h1", {
  margin: "0 0 32px",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
});

const Image = styled("img", {
  width: "90%",
  objectFit: "contain",
  maxWidth: "436px",
  "@md": {
    margin: "0px 0px",
  },
});

const Heading = styled("h1", {
  color: "$white",
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "40px",
  margin: "0 0 24px",

  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },
  "@md": {
    margin: "0px 0 16px",
    fontSize: "48px",
    lineHeight: "56px",
    color: "$heading",
    maxWidth: "602px",
  },
});

const ButtonsWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  margin: "0 auto 60px",
  "@md": {
    flexDirection: "row",
    margin: "0 auto",
  },
});

const StyledHero = styled(Hero, {
  paddingBottom: "45px",
});

const Description = styled("p", {
  fontSize: "17px",
  color: "$white",
  fontWeight: 400,
  lineHeight: "24px",
  margin: "0 0 24px 0",
  "@md": {
    color: "$text",
  },
});

const InfoBlockSection = styled("section", {
  padding: "24px 0px",
  "@md": {
    padding: "24px 0px 64px 0px",
  },
});

const InfoBlock = styled("div", {
  maxWidth: 1280,
  padding: "32px 16px",
  backgroundColor: "#F4F5F7",
  margin: "0 auto",
  borderRadius: 8,
  "@md": {
    padding: "40px",
  },
});

const InfoBlockTitle = styled("h2", {
  fontWeight: 700,
  color: "$text",
  lineHeight: 1.2,
  fontSize: "24px",
  margin: 0,
});

const InfoBlockDescription = styled("p", {
  margin: "32px 0 0 0",
  color: "$text",
  lineHeight: 1.4,
  fontSize: "17px",
});

// OLD VERSION CAROUSEL  STUFF

// import { Autoplay, FreeMode } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
// const SwiperRealizedList = styled(Swiper, {});

// const SwiperBox = styled("div", {
//   width: "100%",
// });

// const SwiperSlideItem = styled(SwiperSlide, {
//   width: "320px !important",
//   marginLeft: 90,
//   "&:last-child": {
//     marginRight: 20,
//   },
// });

// const SliderBox = styled("div", {
//   "@md": {
//     width: "320px",
//     display: "flex",
//     flexDirection: "column",
//     gap: 8,
//   },
// });

// const ListWrap = styled("div", {
//   background: "$secondaryBackground",
//   height: "280px",
//   padding: "60px 0",
//   margin: "60px auto 0",
//   "@md": {
//     margin: "0",
//     padding: "60px 0",
//   },
// });

// const RealizedList = styled("ul", {
//   display: "flex",
//   listStyle: "none",
//   padding: 0,
//   overflowX: "auto",
//   overflowY: "hidden",
//   margin: "4px 0",
// });

// const Logo = styled("img", {
//   width: 48,
//   height: 48,
// });

// const CompanyTitle = styled("span", {
//   fontWeight: 600,
//   fontSize: "17px",
//   lineHeight: "24px",
//   color: "$heading",
//   marginTop: 12,
// });

// const CompanyText = styled("p", {
//   fontWeight: 400,
//   fontSize: "15px",
//   lineHeight: "20px",
//   color: "$black",
//   marginTop: 0,
// });

// const BranchEl = styled("li", {
//   display: "flex",
//   flexDirection: "column",
//   gap: 8,
//   minWidth: "280px",
//   maxWidth: "290px",
//   marginLeft: "40px",
//   "&:last-child": {
//     marginRight: "40px",
//   },
//   "@md": {
//     minWidth: "360px",
//     maxWidth: "360px",
//     marginLeft: "62px",
//   },
// });

// import Time from "../../images/career/time.svg";
// import Plane from "../../images/career/plane.svg";
// import Factory from "../../images/career/factory.svg";
// import Wheel from "../../images/career/wheel.svg";
// import Rocket from "../../images/career/rocket.svg";
// import Apple from "../../images/career/apple.svg";
// import Book from "../../images/career/book.svg";
// import Coffee from "../../images/career/coffee.svg";
// import Comments from "../../images/career/comments.svg";
// import Cutlery from "../../images/career/cutlery.svg";
// import Euro from "../../images/career/euro-sign.svg";
// import Graduation from "../../images/career/graduation-cap.svg";
// import Sitemap from "../../images/career/sitemap.svg";
// import Smile from "../../images/career/smile.svg";
// import TV from "../../images/career/tv.svg";
// import Users from "../../images/career/users.svg";

// const qualities = [
//   {
//     title: "career.card1.title",
//     logo: Time,
//     text: "career.card1.text",
//   },
//   {
//     title: "career.card2.title",
//     logo: Plane,
//     text: "career.card2.text",
//   },
//   {
//     title: "career.card3.title",
//     logo: Factory,
//     text: "career.card3.text",
//   },
//   {
//     title: "career.card4.title",
//     logo: Wheel,
//     text: "career.card4.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Rocket,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Apple,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Book,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Coffee,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Comments,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Cutlery,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Euro,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Graduation,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Sitemap,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Smile,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: TV,
//     text: "career.card5.text",
//   },
//   {
//     title: "career.card5.title",
//     logo: Users,
//     text: "career.card5.text",
//   },
// ];

//  <ListWrap>
//         {resolution === "mobile" ? (
//           <RealizedList>
//             {qualities.map((item, i) => (
//               <BranchEl key={i}>
//                 <Logo src={item.logo} />
//                 <CompanyTitle>{t(item.title)}</CompanyTitle>
//                 <CompanyText>{t(item.text)}</CompanyText>
//               </BranchEl>
//             ))}
//           </RealizedList>
//         ) : (
//           <SwiperBox>
//             <SwiperRealizedList
//               loop={true}
//               autoplay={{
//                 delay: 5000,
//                 stopOnLastSlide: false,
//                 disableOnInteraction: false,
//               }}
//               freeMode={true}
//               modules={[FreeMode, Autoplay]}
//               slidesPerView={"auto"}
//             >
//               {qualities.map((item, i) => (
//                 <SwiperSlideItem key={i}>
//                   <SliderBox>
//                     <Logo src={item.logo} />
//                     <CompanyTitle>{t(item.title)}</CompanyTitle>
//                     <CompanyText>{t(item.text)}</CompanyText>
//                   </SliderBox>
//                 </SwiperSlideItem>
//               ))}
//             </SwiperRealizedList>
//           </SwiperBox>
//         )}
//       </ListWrap>
