import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { styled } from '../../../stitches.config';
import { Vacancy } from '../../types/vacancy';
import CityIcon from '../../images/career/city.svg';

type Props = {
  vacancies: Vacancy[];
};

export const OpenVacancies: React.FC<Props> = ({ vacancies }) => {
  return (
    <VacancyList>
      {vacancies.map((el, i) => (
        <VacanacyListItem key={i}>
          <VacancyCard to={`/contact/career/${el.attributes.slug}`}>
            <Title>{el.attributes.title}</Title>
            <Box>
              <Icon src={CityIcon} />
              <City>{el.attributes.city}</City>
            </Box>
            <Description>{el.attributes.description}</Description>
          </VacancyCard>
        </VacanacyListItem>
      ))}
    </VacancyList>
  );
};

const VacancyList = styled('ul', {
  padding: 0,
  margin: 0,
});

const VacancyCard = styled(Link, {
  padding: '16px',
  display: 'block',
  color: '$text',
});

const Title = styled('span', {
  fontWeight: 600,
  fontSize: '17px',
  cursor: 'pointer',
  lineHeight: '24px',
  textDecoration: 'none',
  color: '$accent',
  margin: 0,
});
const VacanacyListItem = styled('li', {
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  marginBottom: 20,
  listStyle: 'none',
  borderBottom: '1px solid #F3F3F3',
  borderRadius: '8px',
  backgroundColor: '$white',
  transition: 'all 300ms ease',
  '&:last-child': {
    borderBottom: 'none',
  },
});

const City = styled('p', {
  margin: 0,
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '20px',
  color: '$screedDisplayText',
});

const Icon = styled('img', {
  margin: '2px 0',
});

const Box = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 6,
});

const Description = styled('p', {
  margin: '8px 0 0px',
});
